import React from 'react';

const CoinInfo = () => {
    return (
        <div>
            코인정보
        </div>
    );
};

export default CoinInfo;